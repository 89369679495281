export default class Unit {

  constructor(name, type, cost, attack, defense, move, boost, carry) {
    this.name = name;
    this.type = type;
    this.cost = cost;
    this.attack = attack;
    this.defense = defense;
    this.move = move;
    this.boost = boost;
    this.carry = carry;
  }

}